/* ========================================================================
 * DOM-based Routing
 * Based on //goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        window.viewportUnitsBuggyfill.init();
        if (document.getElementById('patched-viewport')) {
          window.addEventListener('resize', viewportUnitsBuggyfill.refresh, true);
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var AutoCompleteUrl;
        if(local.remote.autocompleteurl){
          AutoCompleteUrl = local.remote.autocompleteurl;
        }else{
          AutoCompleteUrl = "https://species-ws.nbnatlas.org/auto?q=";
        }
        var NBN_ATLAS_SITE_VARS = {
          baseAutoCompleteUrl : AutoCompleteUrl
        };

        var atlasSpecies = new Bloodhound({
          datumTokenizer: function(datum){
            Bloodhound.tokenizers.obj.whitespace(datum.value);
          },
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          remote: {
            url: NBN_ATLAS_SITE_VARS.baseAutoCompleteUrl + '%QUERY',
            wildcard: '%QUERY',
            filter: function (species_data){
              return $.map(species_data.autoCompleteList, function (result) {

                 if(result.commonNameMatches.length > 0) {
                    return {
                      value: result.matchedNames[0],
                      scientificName: result.name,
                      guid : result.guid,
                      matchType: 'common'
                    };
                 } else if(result.scientificNameMatches.length > 0) {
                    return {
                      value: result.matchedNames[0],
                      rank: result.rankString,
                      guid : result.guid,
                      matchType: 'scientific'
                    };
                 } else {
                    //for non-taxa
                    return {
                      value: result.matchedNames[0],
                      guid : result.guid,
                      matchType: 'other'
                    };
                 }
              });
            }
          }
        });

        atlasSpecies.initialize();

        $('.main-search #main-search, .navbar-form #navbar-search').typeahead(null, {
          name: 'atlas-species',
          highlight: true,
          hint: true,
          minLength: 3,
          displayKey: 'value',
          source: atlasSpecies.ttAdapter(),
          templates: {
            suggestion: function(data){
              var baseSpeciesURL = local.remote.public.species + 'species/';
              var speciesLink = '<a href="'+baseSpeciesURL+data.guid+'">';
              if(data.matchType === 'common'){
                return speciesLink + '<strong>' + data.value + '</strong> – ' + data.scientificName + '</a>';
              } else if (data.matchType === 'scientific'){
                return speciesLink + '<strong>' + data.value + '</strong> – ' + data.rank + '</a>';
              } else {
                return speciesLink + '<strong>' + data.value + '</strong></a>';
              }
            }
          }
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        function shuffle(array) {
          var currentIndex = array.length, temporaryValue, randomIndex ;

          // While there remain elements to shuffle...
          while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
          }

          return array;
        }

        var remoteData = {
          "pageSize": "0",
          "facets":  "species_subgroup"
        };
        if(local.remote.atlas.variant){
          remoteData.q = local.remote.atlas.variant;
        }

        $.ajax({
          url: local.remote.ws.records+"occurrences/search.json",
          data: remoteData,
        }).done(function( data4 ) {

          /*console.log(data4);*/

          var rows = data4.facetResults[0].fieldResult.length;
          var species_subgroup = [];
          var biodiversity_data = '';

          for (var i = 0; i < rows; i++) {
              if(data4.facetResults[0].fieldResult[i]){
                if(data4.facetResults[0].fieldResult[i].label !== '' &&
                  data4.facetResults[0].fieldResult[i].label !== 'Unknown'){
                  species_subgroup.push({ label: data4.facetResults[0].fieldResult[i].label, count: data4.facetResults[0].fieldResult[i].count });
                }
              }
          }

          shuffle(species_subgroup);

          for( var j = 0; j < 7; j++){
            biodiversity_data += '<li><a href=\''+local.remote.public.records+'occurrences/search?q=species_subgroup:"'+encodeURI(species_subgroup[j].label)+'"\'">'+species_subgroup[j].count.toLocaleString()+'<small>'+species_subgroup[j].label+'</small></li>';
          }

          $('.atlas-species-random-list').html( '<ul>' + biodiversity_data + '</ul>' );

        });

        (function(){
          $('#partners-carousel .item').each(function(){
            var itemToClone = $(this);

            for (var i=1;i<4;i++) {
              itemToClone = itemToClone.next();

              // wrap around if at end of item collection
              if (!itemToClone.length) {
                itemToClone = $(this).siblings(':first');
              }

              // grab item, clone, add marker class, add to collection
              itemToClone.children(':first-child').clone()
                .addClass("cloneditem-"+(i))
                .appendTo($(this));
            }
          });
        }());

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        if($('#modal-popup').hasClass('trigger')){
          $('#modal-popup').modal('show');
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
